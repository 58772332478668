import React, {useEffect, useState} from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import {ArrowIcon, ShoppingCartIcon, SunriseLogo, UserIcon} from "assets";
import "./Navigation.scss"
import classNames from "classnames";
import Button from "Components/Button/Button";
import Dropdown from "Components/Dropdown/Dropdown";
import {useTranslation} from "react-i18next";

const authLocations = ['/profile',"/login"]

function Navigation() {

	const location = useLocation();
	const [expanded,setExpanded] = useState(false);
	const [subNavOption,setSubNavOption] = useState(null);
	const { i18n,t } = useTranslation();

	useEffect(()=>{
		setSubNavOption(null);
	},[expanded]);

	const changeLanguage = (lng) => {
	  i18n.changeLanguage(lng);
	  localStorage.setItem('i18nextLng', lng);
	};

	const renderLinks = () => {
		// Todo - adding an animation here would be a good idea
		
		if(subNavOption === "language"){
			return <div className="subpage">
				<div className="subpage-controls">
					<Button
						variant="bare"
						theme="secondary"
					 	action={()=>{setSubNavOption(null)}}
					 >
						<ArrowIcon/>
						{t("goBack")}
					</Button>
				</div>
				
				<div className="subpage-links languages">
					<Button action={()=>{changeLanguage("en")}} theme={i18n.language==="en"?"tertiary":"secondary"}>
						<span className="flagIcon">{getLangText("en").icon}</span>
						{getLangText("en").txt}
					</Button>
					<Button action={()=>{changeLanguage("es")}} theme={i18n.language==="es"?"tertiary":"secondary"}>
						<span className="flagIcon">{getLangText("es").icon}</span>
						{getLangText("es").txt}
					</Button>
				</div>

			</div>
		}else if(subNavOption === "services"){
			return <div className="subpage">
				<div className="subpage-controls">
					<Button
						variant="bare"
						theme="secondary"
					 	action={()=>{setSubNavOption(null)}}
					 >
						<ArrowIcon/>
						{t("goBack")}
					</Button>
				</div>

				<div className="subpage-links">
					<NavLink to="/services/health">
						{t("healthCare")}
					</NavLink>
				</div>

			</div>
		}else{
			return <>
				<NavLink to="/">
					{t("home")}
				</NavLink>

				<MobileDropdown.Services
					setNavOption={setSubNavOption}
				/>

				<MobileDropdown.LanguageSelector
					setNavOption={setSubNavOption}
					changeLanguage={changeLanguage}
					i18n={i18n}
				/>

				<NavLink to="/about">
					{t("aboutus")}
					
				</NavLink>
				
				<NavLink to="/products">
					{t("store")}
				</NavLink>

				{process.env.REACT_APP_MAIN_URL && <NavLink to={process.env.REACT_APP_MAIN_URL}>
					E-Wallet
				</NavLink>}

				<NavLink to="/contact">
					{t("contact")}
				</NavLink>
			</>
		}
	}

	return (
		<div className="Navigation">
			
			<div className="links">

				<div className={classNames("hambIconWrapper",{expanded})} onClick={() => {
					setExpanded(!expanded)
				}}>
					<ArrowIcon/>
				</div>


				<NavLink to="/" className="logo noOutline">
					<SunriseLogo/>
				</NavLink>


				<div className={classNames("collapsableLinks",{expanded})}>
					{renderLinks()}
				</div>

				<div className="linkGroup">
					
					<NavLink to="/checkout" className="checkout noOutline">
						<ShoppingCartIcon/>
					</NavLink>

					<NavLink to={"/profile"} className="profile noOutline">
						{authLocations.includes(location.pathname.split("/")[1])?
							<UserIcon.Filled/>
						:
							<UserIcon.Fade/>		
						}
					</NavLink>
				</div>

			</div>

			<div className="ribbon"/>
		</div>
	);
}

export default Navigation;

const MobileDropdown = {
	Services : ({setNavOption}) => {
	
		const navigate = useNavigate();
		const location = useLocation();
		const {t} = useTranslation();
		const startsWith = (path) => {
			return location.pathname.startsWith(path)
		}
		
		return (<>
	
			<Button
				theme="secondary"
				variant="bare"
				className="menuToggle button link"
				action={()=>{setNavOption("services")}}
			>
				{t("services")}
				<ArrowIcon/>
			</Button>
	
			<Dropdown 
				title={t("services")}
				className="menuToggle dropdown"	
			>	
	
				<Dropdown.Item
					selected={startsWith("/services/health")}
					action={()=>{navigate("/services/health")}}
				>
					{t("healthCare")}
				</Dropdown.Item>
	
			</Dropdown>
	
		
		</>)
	},
	LanguageSelector: ({setNavOption,changeLanguage,i18n}) => {

		return (<>
	
			<Button
				theme="secondary"
				variant="bare"
				className="menuToggle button link"
				action={()=>{setNavOption("language")}}
			>
				{getLangText(i18n.language).icon}
				{getLangText(i18n.language).txt}
				<ArrowIcon/>
			</Button>
	
			<Dropdown 
				title={getLangText(i18n.language).icon}
				className="menuToggle dropdown"	
			>	
				<Dropdown.Item
					selected={i18n.language==="en"}
					action={()=>{changeLanguage("en")}}
				>
					<span>
						{getLangText("en").icon}
					</span>
					{getLangText("en").txt}
				</Dropdown.Item>
	
				<Dropdown.Item
					selected={i18n.language==="es"}
					action={()=>{changeLanguage("es")}}
				>
					<span>
						{getLangText("es").icon}
					</span>
					{getLangText("es").txt}
				</Dropdown.Item>
			</Dropdown>
		</>)
	}
};


const getLangText = (lng) => {
	const LangAliases = {
		'en-US': {
			icon: <img src="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/gb.svg" alt="English" className="flag-icon" />,
			txt: "English"
		},
		'es': {
			icon: <img src="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/es.svg" alt="Spanish" className="flag-icon" />,
			txt: "Español"
		},
		'default': {
			icon: <img src="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/gb.svg" alt="English" className="flag-icon" />,
			txt: "English"
		}
	}

	return LangAliases[lng] || LangAliases['default'];
}


