import React, {useEffect, useState} from "react";
import {useCart, useCartActions} from "Context/Cart";
import {useLocation, useNavigate, useParams} from "react-router";
import {formatCurrency} from "Utils/TextFormat";
import {axiosWithAuth} from "Utils/axiosWithAuth";
import {Button, Card, Header} from "Components";
import "./Product.scss";
import {useTranslation} from "react-i18next";

function Product() {
	const {state} = useLocation();
	const {id} = useParams();
	const navigate = useNavigate();
	const [product,setProduct] = useState({});
	const {t} = useTranslation();
	
	const {addToCart} = useCartActions();
	const cart = useCart();

	useEffect(()=>{
		if(state){
			setProduct(state);
		}else{
			fetchProduct();
		}
	},[state]);

	const fetchProduct = () => {
		axiosWithAuth().get("/eco/products/"+id).then(res=>{
			setProduct(res.data);
		}).catch(err => {
			if(err?.response?.status === 500){
				alert("Se produjo un error al obtener el producto. Por favor, inténtalo de nuevo más tarde");
			}
			console.log(err);

		})	
	}


	return (
		<div className="Product">
			<Card className="wrapper" centered>
				
				<div className="leftPanel">
					<Card className="media">
						<div className="img-wrapper">
							{product.imageUrl?
								<img src={product.imageUrl}/>
							:null}
						</div>
					</Card>
				</div>

				<div className="rightPanel">
					<div className="info">
						<Header title={product?.name} subtitle={formatCurrency(product?.priceCents/100)}/>
						<p className="description">
							{product?.description}
						</p>
					</div>

					<div className="actions">
						{cart[id]?<>
							<Button.ProductQuantity
								product={cart[id]}
								/>
							<Button
								className="pay"
								variant="secondary"
								action={() => {navigate("/checkout")}}
							>
								{t("payNow")}
							</Button>
						</>
						:
							<Button
								className="addToCart"
								action={() => {addToCart({
									...product, // Asuming keys: id,name,description,price
									sku: product.name,
									quantity: 1
								})}}
							>
								{t("addToCart")}
							</Button>
						}
						
						
						
						
					</div>				
				</div>
			</Card>
		</div>
	);
}

export default Product;

// function QuantityButton({product}){

// 	const [newQuantity,setNewQuantity] = useState(product.quantity);
// 	const {deleteItem, setQuantity} = useCartActions();

// 	useEffect(()=>{
// 		if(newQuantity === '') return

// 		if(Number(newQuantity) <= 0){
// 			deleteProduct();
// 		}else{
// 			setQuantity(product.id, newQuantity)
// 		}
// 	},[newQuantity]);

// 	const deleteProduct = () => {
// 		if(window.confirm("Retirar del carrito")){
// 			deleteItem(product.id)
// 		}else{
// 			setNewQuantity(1)
// 		}
// 	}

// 	return <Card.Secondary className={"quantityButton"}>
// 		<Button
// 			variant="bare"
// 			theme="secondary"
// 			action={()=>{setNewQuantity(product.quantity-1)}}
// 		>-</Button>
// 		<p className="quantity">
// 			{product.quantity}
// 		</p>
// 		<Button
// 			variant="bare"
// 			theme="secondary"
// 			action={()=>{setNewQuantity(product.quantity+1)}}
// 		>+</Button>
// 	</Card.Secondary>
// }