import {axiosWithAuth} from "Utils/axiosWithAuth";
import {useState, createContext, useContext, useEffect} from "react";
import {useTranslation} from "react-i18next";

const AuthContext = createContext();
const AuthLoginContext = createContext();
const AuthLogoutContext = createContext();
const AuthInitialLoadContext = createContext();
const UserWalletsContext = createContext();

export function useAuth() {
	return useContext(AuthContext)
}
export function useAuthLogin() {
	return useContext(AuthLoginContext)
}
export function useAuthLogout() {
	return useContext(AuthLogoutContext)
}
export function useInitalLoad() {
	return useContext(AuthInitialLoadContext)
}
export function useUserWallets() {
	return useContext(UserWalletsContext)
}

export function AuthProvider ({children}){
	const [userData,setUserdata] = useState({}); // Logged user
	const [userWallets,setUserWallets] = useState([]);
	const [initialLoad,setInitialLoad] = useState(false);
	const {t} = useTranslation();

	useEffect(()=>{
		axiosWithAuth().get("users").then(res=>{
			setUserdata(res.data);
		}).catch(err=>{
			if(err.message){
				console.log(err);
			}
			if(err.response){
				console.log(err);
			}
		}).finally(()=>{
			setInitialLoad(true);
			if(process.env.REACT_APP_PLATFORM){
				window.document.title = process.env.REACT_APP_PLATFORM;
			}
		})
	},[]);

	useEffect(()=>{
		if(userData?.uuid){
			fetchUserWallets();
		}
	},[userData]);

	function fetchUserWallets(){
		axiosWithAuth().get("users/wallets").then(res=>{
			setUserWallets(res.data);
		}).catch(err=>{
			console.log(err);
		})
	}

	function login(data){
		axiosWithAuth().post("auth/login", data).then(res=>{
			console.log(res);
			setUserdata(res.data);
		}).catch(err => {
			if(err.response?.status === 404){
				// We return response bc we dont need to as we handle this case
				console.log(err);
				alert(t("errors.notAvailableAtThisMoment"));
			}else if(err.response?.status === 401){
				alert(t("errors.401"));
			}else{
				alert(t("errors.500"));
			}
		})
	};

	function logout(){
		axiosWithAuth().post("auth/logout").then(res=>{
			window.localStorage.removeItem("cart");
			setUserdata(false);
		})
	}

	return (
		<AuthContext.Provider value={userData}>
			<AuthInitialLoadContext.Provider value={initialLoad}>
				<AuthLoginContext.Provider value={login}>
					<AuthLogoutContext.Provider value={logout}>
						<UserWalletsContext.Provider value={userWallets}>
							{children}
						</UserWalletsContext.Provider>
					</AuthLogoutContext.Provider>
				</AuthLoginContext.Provider>
			</AuthInitialLoadContext.Provider>
		</AuthContext.Provider>
	)


}