import React, {useEffect, useState} from "react";
import "./Orders.scss";
import {axiosWithAuth} from "Utils/axiosWithAuth";
import {Dropdown, InputField, Table} from "Components";
import {formatDate} from "Utils/TextFormat";
import {formatCurrency} from "Utils/TextFormat";
import {EyeIcon, SearchIcon} from "assets";
import {useNavigate} from "react-router";
import {useTranslation} from "react-i18next";

function Orders(){
	const [orders,setOrders] = useState([]);
	const navigate = useNavigate();
	const {t} = useTranslation();

	useEffect(()=>{
		axiosWithAuth().get("eco/orders").then(res => {
			if(res.status === 200){
				setOrders(res.data.orders);
			}
		}).catch(err => {
			if(err?.response?.status === 500){
				alert("Se produjo un error al obtener las ordenes. Por favor, inténtalo de nuevo más tarde");
			}
			console.log(err);
		});
	},[]);

	return (
		<div className="Orders">
			<div className="row nowrap gp-1 controls">
				{/* TODO
				
				<InputField
					className="search"
					name="search"
					inLineSlot={<SearchIcon
						height={18}
						width={18}
					/>}
					fitContent
				/>

				<Dropdown title={"Ordenar Por"} className="sort">
					<Dropdown.Item>
						Fecha de compra 
					</Dropdown.Item>
				</Dropdown> */}
			</div>

			<Table 
				className={"OrdersTable"}

				headers = {<>
					<th  className="iconCell-header"></th>
					<th>{t("dateTime")}</th>
					<th>{t("purchaseDetails")}</th>
					<th>{t("paymentMethod")}</th>
					<th>{t("paymentStatus")}</th>
					<th>{t("total")}</th>
				</>}
				xgrowVertical={true}
			>
				{orders.map(order => <Table.Row 
					key={order.orderId}
				>
					<Table.RowItem
						className={"iconCell-rowItem"}
					>
						<EyeIcon
							// onClick={() => {navigate(`${order.orderId}`, {state:order})}}
							onClick={() => {navigate(`/order/${order.orderId}`)}}
						/>
					</Table.RowItem>
					<Table.RowItem>
						{formatDate(order.createdAt)} 
					</Table.RowItem>
					<Table.RowItem>
						{order?.products?.map(p => <div className="productDetails" key={p.id}>
							<p>{p.name} (x{p.quantity}) {formatCurrency(p.priceCents/100 * p.quantity)}</p>
						</div>)}
					</Table.RowItem>
					<Table.RowItem>
						{order?.payment?.type || t("Pending")}
					</Table.RowItem>
					<Table.RowItem>
						{t(`orderStatus.${order.status}`)}
					</Table.RowItem>
					<Table.RowItem>
						{formatCurrency(order?.products?.reduce( (acc,p) => acc + (p.quantity * p.priceCents/100) ,0))}
					</Table.RowItem>
				</Table.Row>)}
			</Table>
		</div>
	)
}

export default Orders;


