import {Button, Card, Dropdown, Header, InputField, Modal} from "Components";
import {useCart} from "Context/Cart";
import React, {useEffect, useState} from "react";
import "./Payment.scss";
import {formatCurrency} from "Utils/TextFormat";
import {useNavigate} from "react-router";
import {useAuth} from "Context/Auth";
import {axiosWithAuth} from "Utils/axiosWithAuth";
import classNames from "classnames";
import {NavLink} from "react-router-dom";
import {useTranslation} from "react-i18next";

function Payment() {

	const cart = useCart();
	const navigate = useNavigate();
	const [paymentMethod,setPaymentMethod] = useState(null);
	const {t} = useTranslation();
	const userData = useAuth();

	const handlePayment = (e) => {
		e.preventDefault();

		const products = Object.values(cart).map(p => ({
			id:p.id,
			quantity:p.quantity,
		}));

		if(products.length === 0){
			// Something bad happened reload the page.
			window.location.reload();
			return;
		}

		// Assigned to logged user
		axiosWithAuth().post("/eco/orders", {
			products:products,
			paymentMethod,
		}).then(res => {
			if(res.status === 201){
				navigate("/order/"+res.data[0]?.orderId)
			}
		}).catch(err => {
			if(err.response?.status === 504){
				alert(t("errors.notAvailableAtThisMoment"))
			}else{
				console.log(err);
				alert("errors.500")
			}
		})
	}

	const allowPayment = () => {
		if(!paymentMethod){
			return true;
		}

		if(!userData.uuid && paymentMethod==="Wallet"){
			return true;
		}

		return false;
	}

	if(Object.values(cart).length === 0){
		return (
		<div className="Checkout">
			{/* Nono */}
			<div className="wrapper">
				<p>{t("noProducts")}</p>
				<NavLink to="/products">
					{t("checkProductsHere")}
				</NavLink>
			</div>
		</div>
		)
	}

	return (
		<div className="Payment">
			<form onSubmit={handlePayment} className="wrapper">
				<Header 
					title={t("Payment")} 
					alignText={"center"}
				/>
			
				<Card.ProductsTotal
					products={Object.values(cart)}
				>
					<Button
						variant="outlined"
						theme="secondary"
						action={() => { navigate("/products")}}
					>
						{t("buyMore")}
					</Button>
				</Card.ProductsTotal>

				<div className="div">
				<Card className="paymentMethods">
					<Dropdown title={paymentMethod || t("paymentMethods")}>
						<Dropdown.Item
							action={() => {setPaymentMethod("Wallet")}}
							selected={paymentMethod==="Wallet"}
						>
							Wallet
						</Dropdown.Item>

						<Dropdown.Item
							action={() => {setPaymentMethod("Stripe")}}
							selected={paymentMethod==="Stripe"}
						>
							Stripe
						</Dropdown.Item>
					</Dropdown>

					<Button 
						className="payNow"
						theme="secondary"
						type="submit"
						disabled={allowPayment()}
					>
						{t("payNow")}
					</Button>
					</Card>
					<p className="disclaimer">
						{t("Payment Disclaimer")}
					</p>
				</div>

			</form>
		</div>
	);
}

export default Payment;