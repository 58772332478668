import React, {useState,useEffect} from "react";
import {useLocation, useNavigate, useParams} from "react-router";
import "./Orders.scss";
import {Button, Header, Card, Dropdown} from "Components";
import {formatDate} from "Utils/TextFormat";
import {formatCurrency} from "Utils/TextFormat";
import {axiosWithAuth} from "Utils/axiosWithAuth";
import {useTranslation} from "react-i18next";
import {Spinner} from "assets";
import {useAuth} from "Context/Auth";

function Order() {

	const {id} = useParams();
	const {state} = useLocation();
	const [order,setOrder] = useState(null);
	const [payment,setPayment] = useState(null);
	const user = useAuth();
	const { t } = useTranslation();
	const navigate = useNavigate();

	useEffect(()=>{
		if(state){
			setOrder(state);
		}else{
			fetchOrder(id);
		}
	},[state]);

	const fetchOrder = (id) => {
		axiosWithAuth().get(`eco/orders/${id}`,{params:{
			includeProducts:true
		}}).then(res=>{
			if(res.status === 200 && res.data?.order){
				console.log(res.data);
				setOrder(res.data.order);
				setPayment(res.data.payment || {});
			}
		}).catch(err => {
			if(err?.response?.status === 500){
				alert("Se produjo un error al cargar la orden. Por favor, inténtalo de nuevo más tarde");
			}	
			console.log(err);
		})
	}

	const retryPayment = (paymentMethod) => {

		if(paymentMethod === "Wallet"){
			navigate("/order/"+id)
			return null;
		}

		// TODO - create a context for this
		axiosWithAuth().get(`store/orders/${id}/retry`,{
			params:{
				paymentMethod
			}
		}).then(res=>{
			if(res.status === 201){
				window.location.href = res.data.paymentUrl;
			}
		}).catch(err => {
			if(err?.response?.status === 409){
				alert("El pago ya ha sido realizado.");			
				// TODO - create an alert to show to the user the payment was already paid
				window.location.reload();
				// navigate(`success`, {state:order})
			}
			else if(err?.response?.status === 400){
				alert("La orden ya ha sido completada.");							
			}
			else if(err?.response?.status === 500){
				alert("Se produjo un error al reintentar el pago. Por favor, inténtalo de nuevo más tarde");
			}else{
				console.log(err);
			}

		})	
	}

	if(!order){
		return <div className="loading">
			<Spinner/>
		</div>
	}

	return (
		<Card className="Order">
			{<Header title={order.orderId} alignText={"center"}/>}
			
			<div className="Order-info">

				<div className="row nowrap">
					<p className="key">{t("name")}:</p>
					<p className="value">{user.fullName}</p>
				</div>
				<div className="row nowrap">
					<p className="key">{t("email")}:</p>
					<p className="value">{user.email}</p>
				</div>
				{user.abbasoonCode && 
					<div className="row nowrap">
						<p className="key">{t("abbasoonCode")}:</p>
						<p className="value">{user.referrerId}</p>
					</div>
				}

				<div className="row nowrap">
					<p className="key">{t("referrerCode")}:</p>
					<p className="value">{user.fullName}</p>
				</div>
				<div className="row nowrap">
					<p className="key">{t("Payment Date")}:</p>
					<p className="value">{formatDate(payment.completedAt)}</p>
				</div>

				<div className="row nowrap"> 
					<p className="key">{t("Total")}:</p>
					<p className="value">{formatCurrency(order.products.reduce((curr,acc) => acc.priceCents * acc.quantity/100  + curr, 0))}</p>
				</div>
				<div className="row nowrap"> 
					<p className="key">{t("paymentMethod")}:</p>
					<p className="value">{payment.type}</p>
				</div>
				<div className="row nowrap"> 
					<p className="key">{t("Description")}:</p>
					<div className="value-wrapper">
						{order.products.map(p => <p key={p.name}>
							{p.name} (x{p.quantity}) {formatCurrency(p.priceCents/100)}
						</p>)}
					</div>
				</div>
			</div>

			<div className="row nowrap gp-1 actions">
				
				
				<Button
					variant="outlined"
					theme="secondary"
					action={()=>{navigate("/profile/orders")}}
				>
					{t("View details")}
				
				</Button>
				
				{order.status == "completed" ? 
					null
					// <Button
					// 	theme="secondary"
					// >
					// 	Descargar factura
					// </Button>
				: 
					// Todo we can create a common component for this
					<Dropdown
						title={t("Try again")}
					>
						<Dropdown.Item
							action={()=>{retryPayment("Wallet")}}
						>
							Wallet {order?.payment.type === "Wallet" && "(Original)"}
						</Dropdown.Item>
						<Dropdown.Item
							action={()=>{retryPayment("Stripe")}}
						>
							Stripe {order?.payment.type === "Stripe" && "(Original)"}
						</Dropdown.Item>
					</Dropdown>
				}
			</div>
		</Card>
	);
}

export default Order;
