import React,{useState,useEffect} from "react";
import {Header,Button, Dropdown, Card, Modal, InputField} from "Components";
import {Spinner, StatusIcon} from "assets";
import {axiosWithAuth} from "Utils/axiosWithAuth";
import {useNavigate, useParams} from "react-router";
import Order from "Views/Profile/Orders/Order";
import "./OrderStatus.scss";
import {useAuth, useUserWallets} from "Context/Auth";
import {useTranslation} from "react-i18next";

function OrderStatus() {

	const [order,setOrder] = useState(null);
	const [payment,setPayment] = useState(null);
	const [isLoading,setIsLoading] = useState(false);
	const {id} = useParams();
	const {t} = useTranslation();
	const userData = useAuth();


	useEffect(()=>{
		if(id){
			fetchOrder(id);
		}
	},[id]);

	const fetchOrder = (id) => {
		setIsLoading(true);
		axiosWithAuth().get(`eco/orders/${id}`).then(res=>{
			if(res.status === 200 && res.data?.order){
				setOrder(res.data.order);
				setPayment(res.data.payment || {});
			}
		}).catch(err => {
			console.log(err);
			if(err?.response?.status === 500){
				alert("Se produjo un error al cargar la orden. Por favor, inténtalo de nuevo más tarde");
			}			
		}).finally(_ => {
			setIsLoading(false);
		})
	}

	const assignPayment = (paymentMethod) => {
		axiosWithAuth().post(`eco/orders/payment`,{
			orderId:id,
			paymentMethod
		}).then(res=>{
			if(res.status === 200){
				setPayment(res.data.payment);
			}
		}).catch(err=>{
			const errDetail = err?.response?.data?.detail;


			if (errDetail === 'orderNotPending') {
				alert(t("Order is not in pending state"));
			} else if (errDetail === 'invalidPaymentMethod') {
				alert(t("Invalid payment method"));
			} else if (errDetail === 'orderNotFound') {
				alert(t("Order not found"));
			}else{
				console.log(err);
				alert(t("Error assigning payment"));
			}
		})
	}
	const retryPayment = (paymentMethod) => {

		axiosWithAuth().get(`eco/orders/${id}/retry`,{
			params:{
				paymentMethod
			}
		}).then(res=>{
			if(res.status === 201){
				if(userData?.uuid && paymentMethod === "Stripe"){
					window.location.href = res.data.paymentUrl;
				}else{
					window.location.reload();
				}
			}
		}).catch(err => {
			if(err?.response?.status === 409){
				alert("El pago ya ha sido realizado.");			
				// TODO - create an alert to show to the user the payment was already paid
				window.location.reload();
				// navigate(`success`, {state:order})
			}
			else if(err?.response?.status === 400){
				alert("La orden ya ha sido completada.");							
			}
			else if(err?.response?.status === 500){
				alert("Se produjo un error al reintentar el pago. Por favor, inténtalo de nuevo más tarde");
			}else{
				console.log(err);
			}

		})	
	}

	const renderStatusPage = () => {
		if(order.status==="completed"){
			return <Success payment={payment}/>
		}else if(order.status==="pending"){
			return <Pending retryPayment={retryPayment} assignPayment={assignPayment} order={order} payment={payment}/>
		}else if(order.status==="cancelled"){
			return <Cancelled/>
		}
	}

	if(!order){
		return <Spinner/>
	}

	return (
		<div className="OrderStatus">
			<div className="wrapper">
				{isLoading?
					<Spinner/>
					:renderStatusPage()
				}
			</div>
		</div>
	);
}

export default OrderStatus;


function Success() {
	const {t} = useTranslation();
	return (<>
		<div className="topInfo">
			<StatusIcon.Success/>
			<Header title={t("Order completed sucessfully")}/>
		</div>
		<Order/>
	</>
	);
}

function Cancelled() {

	const {t} = useTranslation();
	const navigate = useNavigate();

	return (<>
		<div className="topInfo">
			<StatusIcon.Failure/>
			<Header title={t("Order cancelled")}/>
		</div>
		<div className="bottomInfo">
			<p>
				{t("Order cancelled message")}
			</p>
			
			<div className="actions cancelled">
				<Button
					action={()=>{navigate("/contact")}}
					theme="secondary"
					variant="outlined"  // This button will be outlined
				>
					{t("Contact support")}
				</Button>
				<Button
					action={()=>{navigate("/profile/orders")}}
					theme="secondary"
				>
					{t("View orders")}
				</Button>
			</div>
		</div>
	</>
	);
}

function Pending({retryPayment,assignPayment,order,payment}){
	const [showWalletModal,setShowWalletModal] = useState(false);
	const {t} = useTranslation();

	const handlePaymentClick= () => {
		if(payment?.type === "Wallet"){
			setShowWalletModal(order);
		}else if(payment?.type === "Stripe"){
			window.open(payment.paymentUrl, "_blank");
		}else{
			alert("Metodo de pago no soportado. Cambia el metodo de pago y vuelve a intentarlo.");
		}
	}


	const handlePayment = (paymentMethod) => {
		if(!payment?.type){
			assignPayment(paymentMethod);
		}else{
			retryPayment(paymentMethod);
		}
	}

	const cancelOrder = () => {
		axiosWithAuth().get(`eco/orders/${order.orderId}/cancel`).then(res=>{
			if(res.status === 200){
				window.location.reload();
			}else{
				console.log("Unhandled status code", res.status);
				window.location.reload();
			}
		}).catch(err=>{
			if(err?.response?.data?.code === "paymentHasBeenPaid"){
				alert("El pago ya ha sido realizado.");
			}else if(err?.response?.data?.code === "paymentIsAlreadyCancelled"){
				alert("El pago ya ha sido cancelado.");
			}else if(err?.response?.data?.code === "orderIsNotInPendingStatus"){
				alert("La orden no está en estado pendiente.");
			}else{
				console.log(err);
				alert("Ha ocurrido un error");
			}
		})

	}


	return (<>
		<div className="topInfo">
			<Header title={t("Pending order")}/>
		</div>
		<div className="bottomInfo">
			<p className="reason">
				{t("Order is pending")}
			</p>

			<div className="actions">
				<div className="action-group">
					{payment?.type && <Button
						action={handlePaymentClick}
						variant="filled"
							theme="secondary"
						>
							{t("Complete payment")}
						</Button>
					}
				</div>

				<Dropdown
					title={payment?.type? t("Change payment method") : t("Select payment method")}
				>
					<Dropdown.Item
						action={()=>{handlePayment("Wallet")}}
					>
						Wallet {payment?.type === "Wallet" && "(Original)"}
					</Dropdown.Item>
					<Dropdown.Item
						action={()=>{handlePayment("Stripe")}}
					>
						Stripe {payment?.type === "Stripe" && "(Original)"}
					</Dropdown.Item>
				</Dropdown>
					

				

				<div className="row nowrap gp-1">
					{payment?.type &&
						<Button variant="outlined" theme="secondary" action={()=>{handlePayment(payment.type)}}>
							{t("Create new payment")}
						</Button>
					}

					<Button
						action={()=>{cancelOrder(order.orderId)}}
						variant="outlined"
						theme="danger"
					>
						{t("Cancel order")}
					</Button>
				</div>
			</div>
		</div>


		<WalletPaymentModal
			order={showWalletModal}
			payment={payment}
			closeModal={()=>{setShowWalletModal(false)}}
		/>

	</>);
}

const WalletPaymentModal = ({order,closeModal,payment}) => {
	
	const [walletId,setWalletId] = useState("");
	const [transactionCode,setTransactionCode] = useState("");
	const [paymentCaptued,setPaymentCaptured] = useState(false);
	const userWallets = useUserWallets();
	const [otherWallet,setOtherWallet] = useState(false);
	const {t} = useTranslation();

	useEffect(()=>{
		if(payment){
			if(payment.responseObject?.status === "pendingValidation"){
				setPaymentCaptured(true);
			}

			// Try to find if they payment has been captured already. ? Raw object will have the wallet id
			// If so set paymentcap and put walletid state
		}
	},[order,payment]);
	
	if(!order) return null;

	const handlePaymentCapture = () => {
		axiosWithAuth().post("eco/payments/wallet/capture",{walletId,orderId:order.orderId}).then(res=>{
			if(res.status === 200){
				setPaymentCaptured(true);
			}else{
				console.log(res);
			}
		}).catch(err=>{
			if(err?.response?.data?.code === "noSuchWallet"){
				console.log(err);
				alert(t("No such wallet"));
			}else{
				console.log(err);
				alert("Ha ocurrido un error");
			}
		})
	}

	
	const handlePaymentComplete = () => {
		axiosWithAuth().post("eco/payments/wallet/complete",{orderId:order.orderId,verificationCode:transactionCode}).then(res=>{
			if(res.status === 201){
				window.location.reload();
			}else{
				console.log(res);
			}
		}).catch(err=>{
			if(err?.response?.data?.code === "CodeDoesntMatch"){
				alert(t("Code invalid"));
			}else{
				console.log(err);
				alert("Ha ocurrido un error");
			}
		})
	}

	const handleResendVerification = () => {
		axiosWithAuth().get("eco/payments/wallet/resendverification",{params:{
			orderId:order.orderId
		}}).then(res=>{
			if(res.status === 200){
				alert("Verification send successfully");
			}else{
				console.log(res);
			}
		}).catch(err=>{
			console.log(err);
			alert("Ha ocurrido un error");
		})
	}
	
	if(!order && !order?.payment) return null;

	console.log(userWallets);

	return <Modal 
		closeModal={closeModal} showCloseIcon={true}
		className={"walletPayment"}
	>
		<Header
			title={t("Complete payment")}
			subtitle={t("You must complete the payment") + ": " + payment?.paymentId}
		/>

		<div className="input">
			{!paymentCaptued && <>


				{userWallets.length > 0 && 
					<Dropdown
						title={userWallets.filter(wallet => wallet.uuid === walletId)[0]?.walletType || t("Select wallet") }
					>
						{userWallets.map(wallet=>{
							return <Dropdown.Item key={wallet.uuid} action={()=>{
								setWalletId(wallet.uuid)
								setOtherWallet(false);	
							}}>{wallet.walletType}</Dropdown.Item>
						})}
						<Dropdown.Item action={()=>{
							setWalletId("");
							setOtherWallet(true)
						}}>{t("Other")}
						</Dropdown.Item>
					</Dropdown>
				}

				{(userWallets.length === 0 || otherWallet) &&
					<InputField
						label={t("Wallet Id")}
						value={walletId}
						setValue={setWalletId}
						name={"walletId"}
						readOnly={paymentCaptued}
					/>
				}
			</>}
			
			{paymentCaptued &&
				<InputField
					label={t("Verification code")}
					value={transactionCode}
					setValue={setTransactionCode}
					name={"transactionCode"}
				/>
			}
		</div>
		

		<div className="actions">
			{paymentCaptued &&
				<Button
					className="retry"
					variant="outlined"
					theme="secondary"
					action={handleResendVerification}
				>
					{t("Resend verification")}
				</Button>
			}
			<Button
				variant="outlined"
				theme="secondary"
				action={closeModal}
			>
				{t("Close")}
			</Button>
			{paymentCaptued?
				<Button
					theme="secondary"
					action={handlePaymentComplete}
					disabled={!transactionCode}
				>
					{t("Complete payment")}
				</Button>
			:
				<Button
					theme="secondary"
					action={handlePaymentCapture}
					disabled={!walletId}
				>
					{t("Capture payment")}
				</Button>
			
			}

		</div>
	</Modal>
}